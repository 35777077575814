<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2023-2023
        <a href="https://wenutu.top"><strong>Wenutu</strong></a> 
        theme by <a href="https://github.com/WebStackPage/WebStackPage.github.io" target="_blank"><strong>Webstack</strong></a>
      </div>
      
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>
